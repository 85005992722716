"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nameof = void 0;
var nameof_1 = require("./src/nameof");
Object.defineProperty(exports, "nameof", {
  enumerable: true,
  get: function () {
    return nameof_1.nameof;
  }
});